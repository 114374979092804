<template>
  <div>
    <v-card>
      <h2 class="pl-2">{{ $t('contest.title')}}</h2>
      <v-btn color="blue" class="float-right mr-10 white--text" @click.stop="addOrEdit(null, 3)">
        {{ $t('contest.addQuiz')}}
        <v-icon class="ml-2 white--text">mdi-alpha-q-circle</v-icon>
      </v-btn>
      <v-btn color="blue" class="float-right mr-10 white--text" @click.stop="addOrEdit(null, 2)">
        {{ $t('contest.addContest')}}
        <v-icon class="ml-2 white--text">mdi-alpha-c-circle</v-icon>
      </v-btn>
      <div style="clear:both"></div>

      <v-card-text>
            <v-row
              align="center"
              justify="center"
            >
              <v-btn-toggle
                v-model="dateFilter"
                mandatory
              >
                <v-btn>
                  Aktualne
                </v-btn>
                <v-btn>
                  Przyszłe
                </v-btn>
                <v-btn>
                  Archiwalne
                </v-btn>
              </v-btn-toggle>
            </v-row>
          </v-card-text>

      <v-data-table
        :headers="headers"
        :items="products"
        :options.sync="options"
        :server-items-length="serverItemsLength"
      >
        <template
          slot="item.type"
          slot-scope="props"
        >{{ props.item.type === 2 ? "Konkurs" : "Quiz" }}</template>
        <template slot="item.actions" slot-scope="props" class="justify-center">
          <v-btn
            class="mx-2"
            icon
            @click="addOrEdit(props.item.id !== 0 ? JSON.parse(JSON.stringify(props.item)): null, props.item.type)"
          >
            <v-icon dark>mdi-pencil</v-icon>
          </v-btn>
          <v-btn class="mx-2" icon @click="deleteItem(props.item)">
            <v-icon dark>mdi-delete</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>

    <confirm-delete-dialog
      endPoint="/article/DeleteArticle"
      ref="confirmDeleteDialog"
      @submit="getDataAsync"
    ></confirm-delete-dialog>
  </div>
</template>
<script>
import ConfirmDeleteDialog from '@/components/Dialogs/ConfirmDeleteDialog'

export default {
  name: 'Contest',
  components: {
    ConfirmDeleteDialog
  },
  data () {
    return {
      dateFilter: null,
      headers: [
        { text: this.$t('contest.name'), value: 'name' },
        { text: this.$t('contest.description'), value: 'description' },
        { text: this.$t('contest.from'), value: 'from' },
        { text: this.$t('contest.to'), value: 'to' },
        { text: 'typ', value: 'type' },
        {
          text: this.$t('product.actions'),
          value: 'actions',
          width: '10%',
          sortable: false
        }
      ],
      products: [],
      options: {},
      serverItemsLength: 0,
      endpoint: '/article/GetArticles'
    }
  },
  methods: {
    async getDataAsync () {
      let url = this.endpoint

      if (this.options?.page) {
        url +=
          '?options.page=' + this.options.page +
          '&options.itemsPerPage=' + this.options.itemsPerPage +
          '&options.sortBy=' + this.options.sortBy +
          '&options.sortDesc=' + this.options.sortDesc +
          '&type=2' +
          '&dateFilter=' + this.dateFilter +
          '&OptionalType=3'
      }

      const result = await this.$http.get(url)
      this.products = result.data.results
      this.serverItemsLength = result.data.totalCount
    },
    deleteItem (item) {
      this.$refs.confirmDeleteDialog.dialog = true
      this.$refs.confirmDeleteDialog.message = item.name
      this.$refs.confirmDeleteDialog.id = item.id
    },
    async addOrEdit (product, type) {
      let routerName = 'AddOrEditQuiz'
      if (type === 2) {
        routerName = 'AddOrEditContest'
      }
      if (product) {
        await this.$router.push({
          name: routerName,
          params: { id: product.id, type: type }
        })
      } else {
        await this.$router.push({ name: routerName, params: { type: type } })
      }
    }
  },
  computed: {
    variablesResponsibleForFetchNewData () {
      return `${this.options}|${this.dateFilter}`
    }
  },
  watch: {
    variablesResponsibleForFetchNewData: {
      handler () {
        this.getDataAsync()
      },
      deep: true
    }
  }
}
</script>
